import { isRequestError } from "api-def";
import { AsyncState } from "react-async-stateful";
import { isDarkColor } from "shared/SharedUtils";

export const PRE_RENDERED_WIDTH_COOKIE_NAME = "GL_preRenderedWidth";

export const isDeepEqual = (a: any, b: any) => {
  if (a === b) {
    return true;
  }
  return JSON.stringify(a) === JSON.stringify(b);
};

export const getFocusableElement = (
  element: HTMLElement | null,
): (HTMLElement & { focus: () => void }) | null => {
  if (!element) {
    return null;
  }
  return element.querySelector(
    "button , [href], input, select, textarea, [tabindex]:not([tabindex='-1'])",
  );
};

export const toAsyncState = <T>(
  func: () => T,
): T extends Promise<any> ? Promise<AsyncState<Awaited<T>>> : AsyncState<T> => {
  const state = AsyncState.create<T>();
  try {
    const valueOrPromise = func();
    if (valueOrPromise instanceof Promise) {
      return valueOrPromise.then(
        (value) => AsyncState.resolve(state, value),
        (error) => AsyncState.reject(state, error),
      ) as any;
    }
    return AsyncState.resolve(state, valueOrPromise) as any;
  } catch (error: any) {
    return AsyncState.reject(state, error) as any;
  }
};

export const toTitleCase = <S extends string | undefined | null>(string: S): S => {
  if (!string) {
    return string;
  }
  // @ts-ignore
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const isTokenExpiredError = (error: any) => {
  if (error.message === "FORCE_REFRESH") {
    return true;
  }
  if (isRequestError(error)) {
    return error.response?.data?.code === "auth/token-expired";
  }
  return false;
};

const VAR_REGEX = /var\((--[^)]+)\)/;

export const getTextColorFromBackground = (backgroundColor: string | undefined | null) => {
  if (backgroundColor) {
    // check for css variable
    const match = VAR_REGEX.exec(backgroundColor);
    if (match) {
      const variable = match[1];
      return `var(${variable}-foreground)`;
    }

    return isDarkColor(backgroundColor) ? "var(--color-light)" : "var(--color-dark)";
  }
  return undefined;
};

export const getFromParam = (from: string) => {
  if (!from.startsWith("/")) {
    throw new Error("From param must start with a /");
  }
  return encodeURIComponent(btoa(from));
};
