"use client";

import clsx from "clsx";
import Link from "next/link";
import React from "react";
import { IconCheck } from "shared/Icons";
import Ripple from "src/components/common/Ripple/Ripple";
import { useFormState } from "src/components/form/Form/Form";
import type { Color } from "src/lib/FrontendTypes";
import { getTextColorFromBackground } from "src/lib/Utils";
import styles from "./Button.module.scss";
export interface ButtonProps extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "color"> {
  color?: Color;
  variant?: "bare" | "normal" | "outline";
  label: string;
  ref?: React.Ref<HTMLElement>;
  size?: "small" | "medium" | "large";
  backgroundColor?: string;
  href?: string;
  loading?: boolean;
  success?: boolean;
  noDisabledStyle?: boolean;
}
const Button = (props: ButtonProps) => {
  const {
    noDisabledStyle,
    className,
    loading,
    backgroundColor,
    success,
    type,
    disabled,
    size,
    ref,
    variant,
    style,
    label,
    href,
    color,
    children,
    ...otherProps
  } = props;
  const textColor = getTextColorFromBackground(backgroundColor);
  const formValid = useFormState(state => state.isValid);
  const formDisabled = formValid === false && (type === "submit" || type === "reset");
  const resolvedDisabled = formDisabled || disabled;
  const actualDisabled = resolvedDisabled || loading || success;
  const Tag = props.href && !actualDisabled ? Link : "button";
  return <Tag ref={ref as any}
  // @ts-ignore
  href={href} disabled={actualDisabled} aria-disabled={resolvedDisabled} aria-label={label} role={"button"} className={clsx(styles.button, className, color ? styles[`color-${color}`] : undefined, styles[`variant-${variant || "normal"}`], size && styles[`size-${size}`], resolvedDisabled && !noDisabledStyle && styles.disabled, loading && styles.loading, success && styles.success)} type={type} style={{
    backgroundColor,
    color: textColor,
    // @ts-ignore
    "--color-button": backgroundColor,
    ...style
  }} {...otherProps} data-sentry-element="Tag" data-sentry-component="Button" data-sentry-source-file="Button.tsx">
      <Ripple data-sentry-element="Ripple" data-sentry-source-file="Button.tsx" />
      {success ? <div className={styles.successOverlay}>
          <IconCheck />
        </div> : null}
      {children !== undefined ? React.Children.map(children, (child, index) => {
      if (typeof child === "string") {
        return <span key={index}>{child}</span>;
      }
      return child;
    }) : label}
    </Tag>;
};
export default Button;